<template>
  <div>
    <div class="az-content-breadcrumb">
      <span>
        <router-link :to="{ name: 'assets-home'}">Asset Registry</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'assets-catalogs'}">Catalog</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'assets'}">Asset</router-link>
      </span>
      <span>{{assetid}}</span>
      <div class="mg-l-auto">
        <router-link :to="{ name: 'assets'}">
          <i class="fa fa-arrow-left" />
          Back
        </router-link>
      </div>
    </div>
    <h2 class="az-content-title mg-b-10">Assets</h2>
    <p class="mg-b-40">{{assetid}}</p>

    <div class="az-content-label mg-b-5">Asset Properties</div>
    <p class="mg-b-20">Properties of an Asset</p>

    <div class="row row-sm mg-b-20">
      <div class="col-lg-3 offset-lg-9">
        <button
          class="btn btn-orange rounded w-100 font-weight-bold"
          data-target="#propertiesModal"
          data-toggle="modal"
          type="button"
        >
          Add Property
        </button>
      </div>
    </div>

    <template v-if="catalogid && assetid">
      <div
        class="table-responsive"
        v-if="properties.length >0"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="tx-left">#</th>
              <th class="tx-left">Property</th>
              <th class="tx-left">Value</th>
              <th class="tx-left">Issued On</th>
              <th class="tx-left">Expiry</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-bind:key="index"
              v-for="(property, index) in properties"
            >
              <td class="masked">{{index+1}}</td>
              <td>{{property.property}}</td>
              <td>{{property.value}}</td>
              <td>{{property.created | timestamp}}</td>
              <td>{{property.expiry | timestamp}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="mg-t-50"
        v-else
      >
        <h4 class="card-title text-muted text-center">No records found</h4>
      </div>

      <div
        class="row"
        v-if="properties.length>0"
      >
        <div class="col-lg-1 pd-l-0">
          <select
            class="form-control mx-sm-3"
            v-model="pageSize"
          >
            <option v-for="size in pageSizes">{{size}}</option>
          </select>
        </div>
        <div class="col-lg-2 pd-t-5 pd-l-10">
          items/page
        </div>
        <div class="col-lg-9">
          <ul class="pagination justify-content-end mg-b-0">
            <li
              @click="prev()"
              class="page-item"
            >
              <a
                class="page-link"
                href="javascript:void(0)"
              >
                <i class="icon ion-ios-arrow-back" />
              </a>
            </li>
            <template v-for="index in pages">
              <li
                :class="{'active': index === page+1 }"
                :key="index"
                @click="goToPage(index-1  )"
                class="page-item"
              >
                <a
                  class="page-link"
                  href="javascript:void(0)"
                >{{index}}</a>
              </li>
            </template>
            <li
              @click="next()"
              class="page-item"
            >
              <a
                class="page-link"
                href="javascript:void(0)"
              >
                <i class="icon ion-ios-arrow-forward" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <add-property-modal
      :assetid="assetid"
      :catalogid="catalogid"
      @refreshProperties="getProperties"
    />
  </div>
</template>

<script>
import AddPropertyModal from "./AddPropertyModal";
import _ from "lodash";

export default {
  name: "Properties",
  components: { AddPropertyModal },
  props: { catalogid: String, assetid: String },
  watch: {
    assetid: function (nv, ov) {
      console.log(nv);
      this.getProperties();
    },
    page: function (nv, ov) {
      this.getProperties();
    },
    pageSize: function (nv, ov) {
      localStorage.setItem("pageSize", nv);
      this.page = 0;
      this.getProperties();
    }
  },
  data() {
    return {
      properties: [],
      pageSizes: [5, 10, 20, 50],
      pageSize: localStorage.getItem("pageSize") || 5,
      page: 0,
      pages: 1,
      pageInfo: {
        page: 0,
        per_page: 5
      }
    };
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      this.properties = [];
      if (typeof this.catalogid !== 'undefined' && typeof this.assetid !== 'undefined') {
        try {
          this.paginate();
          let reply = await this.$assetregistry.get(`/asset-registry/catalogs/${this.catalogid}/assets/${this.assetid}/properties`, { params: this.pageInfo });
          this.properties = reply.data ? reply.data.asset_propertys : [];
          this.pages = reply.data ? Math.ceil(reply.data.total / this.pageSize) : 1;
          this.properties = _.map(this.properties, (property) => {
            switch (property.type) {
              case "String":
                property.type = "Text";
                property["value"] = property.value.value;
                break;
              case "Bool":
                property.type = "Yes/No";
                property["value"] = property.value.value;
                break;
              case "U8":
                property.type = "Number";
                property["value"] = property.value.value;
                break;
              case "U16":
                property.type = "Number";
                property["value"] = property.value.value;
                break;
              case "U32":
                property.type = "Number";
                property["value"] = property.value.value;
                break;
              case "U128":
                property.type = "Number";
                property["value"] = property.value.value;
                break;
            }
            return property;
          });
        } catch (e) {

        }
      }
    },
    paginate() {
      this.pageInfo.page = this.page;
      this.pageInfo.per_page = this.pageSize;
    },
    next() {
      if (this.page + 1 < this.pages) {
        this.page++;
      }
    },
    prev() {
      if (this.page !== 0) {
        this.page--;
      }
    },
    goToPage(n) {
      console.log(n);
      this.page = n;
    }
  }
}
</script>

<style scoped>
</style>