<template>
    <div class="modal" id="propertiesModal">
        <div class="modal-dialog wd-xl-400" role="document">
            <div class="modal-content">
                <div class="modal-body pd-20 pd-sm-40">
                    <button aria-label="Close" class="close pos-absolute t-15 r-20 tx-26" data-dismiss="modal" type="button">
                        <span aria-hidden="true">×</span>
                    </button>
                    <h5 class="modal-title mg-b-20">Create New Property</h5>
                    <div class="form-group">
                        <input class="form-control rounded"
                               id="property_property"
                               name="property_property"
                               placeholder="Enter Property Name"
                               required
                               type="text"
                               v-model="property.assertion">
                    </div><!-- form-group -->
                    <div class="form-group">
                        <select aria-hidden="true"
                                class="form-control select2-no-search select2-hidden-accessible rounded"
                                data-select2-id="13"
                                name="property_data_type"
                                v-model="property.data_type">
                            <option v-bind:value=null>--- Please Select a type ---</option>
                            <option v-bind:key="index" v-for="(type, index) in data_types">{{type}}</option>
                        </select>
                    </div><!-- form-group -->
                    <div class="form-group">
                        <input class="form-control rounded"
                               id="property_string_value"
                               name="property_string_value"
                               placeholder="Enter Property Value"
                               required
                               type="text"
                               v-if="property.data_type === 'Text'"
                               v-model="property.value">
                        <input class="form-control rounded"
                               id="property_number_value"
                               name="property_number_value"
                               placeholder="Enter Property Value"
                               required
                               type="number"
                               v-if="property.data_type === 'Number'"
                               v-model="property.value">

                        <label class="rdiobox" v-if="property.data_type === 'Yes/No'">
                            <input name="rdio"
                                   type="radio"
                                   v-bind:value=true
                                   v-model="property.value">
                            <span>Yes</span>
                        </label>
                        <label class="rdiobox" v-if="property.data_type === 'Yes/No'">
                            <input name="rdio"
                                   type="radio"
                                   v-bind:value=false
                                   v-model="property.value">
                            <span>No</span>
                        </label>
                    </div><!-- form-group -->
                    <div class="form-group">
                        <input class="form-control rounded"
                               id="property_issued"
                               name="property_issued"
                               placeholder="Enter Property Issue date"
                               type="datetime-local"
                               v-model="property.issued">
                    </div>
                    <div class="form-group">
                        <input class="form-control rounded"
                               id="property_expiry"
                               name="property_expiry"
                               placeholder="Enter Property Expiry Date"
                               type="datetime-local"
                               v-model="property.expiry">
                    </div>
                    <button @click="saveProperty" class="btn btn-primary btn-block rounded" data-dismiss="modal">Submit</button>
                </div><!-- modal-body -->
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>

</template>

<script>
    export default {
        name: "AddPropertyModal",
        props: {catalogid: String, assetid: String},
        data() {
            return {
                data_types: ['Text', 'Number', 'Yes/No'],
                property: {
                    assertion: null,
                    data_type: 'Text',
                    value: null,
                    issued: null,
                    expiry: null
                }
            }
        },
        mounted() {
        },
        methods: {
            async saveProperty() {
                let data_type_obj          = this.getDatatype(this.property.data_type, this.property.value);
                let req_obj                = {
                    issued: this.property.issued + ":00",
                    expiry: this.property.expiry + ":00",
                    assertion: this.property.assertion,
                    data_type: data_type_obj.type,
                    private: true
                };
                req_obj[data_type_obj.key] = this.property.data_type === "Number" ? parseInt(this.property.value) : this.property.value;
                try {
                    await this.$assetregistry.post(`/asset-registry/catalogs/${this.catalogid}/assets/${this.assetid}/properties`, req_obj);
                    this.$toastr.s("Property Successfully created!", 'Success');
                    this.property = {
                        assertion: null,
                        data_type: null,
                        value: null,
                        issued: null,
                        expiry: null

                    };
                    this.$emit('refreshProperties');
                } catch(e) {

                }
            },
            getDatatype(type, val) {
                switch(type) {
                    case "Text":
                        return {
                            key: 'string_value',
                            type: 'String',
                        };
                    case "Number":
                        if(val >= 0 && val < 256) {
                            return {
                                key: "u8_value",
                                type: "U8",
                            }
                        } else if(val > 255 && val < 65537) {
                            return {
                                key: "u16_value",
                                type: "U16",
                            }
                        } else if(val > 65536 && val < 4000001) {
                            return {
                                key: "u32_value",
                                type: "U32",
                            }
                        } else if(val) {
                            return {
                                key: "u128_value",
                                type: "U128",
                            }
                        }
                        break;
                    case "Yes/No":
                        return {
                            key: "bool_value",
                            type: "Bool",
                        }

                }
            },
        }
    }
</script>

<style scoped>

</style>